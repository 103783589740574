<template>
  <v-dialog justify="center" :value="value" width="500">
    <v-card color="#fafafa" class="pa-4">
      <v-row justify="center" align="center" dense>
          <b>Invoice Report Successfully Submitted</b>
      </v-row>
      <v-row dense justify="center">
        <v-icon x-large color="green" left>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </v-row>
      <v-row justify="center">
        <a @click="checkReportStatus">Check Invoice Report Status?</a>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'InvoiceReportAlert',

  props: ['value'],

  methods: {
    checkReportStatus () {
      const routeData = this.$router.resolve({ name: 'Dashboard' })
      window.open(routeData.href, '_blank')
      this.$emit('update:value', false)
    }
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { justify: "center", value: _vm.value, width: "500" } },
    [
      _c(
        "v-card",
        { staticClass: "pa-4", attrs: { color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center", dense: "" } },
            [_c("b", [_vm._v("Invoice Report Successfully Submitted")])]
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-icon",
                { attrs: { "x-large": "", color: "green", left: "" } },
                [_vm._v(" mdi-checkbox-marked-circle-outline ")]
              )
            ],
            1
          ),
          _c("v-row", { attrs: { justify: "center" } }, [
            _c("a", { on: { click: _vm.checkReportStatus } }, [
              _vm._v("Check Invoice Report Status?")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }